import React, { Component, useEffect } from 'react';

import { STORAGE_KEY } from './utils';

function ErrorPage() {
  useEffect(() => {
    if (window.confirm("出現非預期錯誤。清除資料以重整？")) {
      window.localStorage.removeItem(STORAGE_KEY);
      window.location.reload();
    }
  }, []);
  return "Error...";
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error here or send it to a logging service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render your custom error page here
      return <ErrorPage />

    }
    // Render the children if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;