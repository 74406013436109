import styled from "@emotion/styled";
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const CourtNumber = styled.div`
  color: white;
  position: absolute;
  left: 4px;
  top: 2px;
  font-size: smaller;
`;

function Court({ group1=null, group2=null, index=null, empty=false, sx={}, ...props }) {
  return (
    <Paper sx={{ position: 'relative', bgcolor: '#66bb6a', padding: '4px', ...sx }} {...props}>
      {empty
        ? <Divider color="white">空</Divider>
        : <Stack spacing={0.5}>
            {group1 !== null && group1.map((e, i) =>
              <Chip label={e} key={i}/>)}
            <Divider color="white" sx={{ borderWidth: '1px' }}/>
            {group2 !== null && group2.map((e, i) =>
              <Chip label={e} key={-i}/>)}
          </Stack>}
      {index !== null &&
        <CourtNumber>
          {index}
        </CourtNumber>}
    </Paper>
  );
}

export default Court;
