import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Court from './Court';
import { shuffle, STORAGE_KEY } from './utils';

const Main = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  & > *::-webkit-scrollbar {
    display: none;
  }
  & > * {
    overflow: auto;
    max-height: 100vh;
  }
`;
const PlayerList = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
`;
const PlayerItem = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-bottom: 1px solid black;
  gap: 5px;
`;
const CourtContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 8px;
  width: 80%;
`;
const CourtButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
  margin: 5px 0;
  width: 100%;
`;
const CourtRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
`;
const CourtCol = styled.div`
  width: 170px;
`;

const synth = window.speechSynthesis;
function speak(text) {
  return new Promise((ok, no) => {
    const utterance = new window.SpeechSynthesisUtterance(text);
    utterance.voice = synth.getVoices().filter(
      e => e.lang.startsWith("zh-TW") || e.lang.startsWith("zh_TW")
    ).pop();
    utterance.rate = 1;
    function ended() {
      ok();
      utterance.removeEventListener("end", ended);
    }
    utterance.addEventListener("end", ended);
    synth.speak(utterance);
  });
}
const DEFAULT_DATA = {
  court: [
    [0, 1, 2, 3],
    [4, 0, 5, 6],
    [7, 8, 0, 9],
  ],
  player: [
    { name: "Player 1", count: 0 },
    { name: "Player 2", count: 0 },
    { name: "Player 3", count: 0 },
    { name: "Player 4", count: 0 },
    { name: "Player 5", count: 0 },
    { name: "Player 6", count: 0 },
    { name: "Player 7", count: 0 },
    { name: "Player 8", count: 0 },
    { name: "Player 9", count: 0 },
    { name: "Player 10", count: 0 },
    { name: "Player 11", count: 0 },
    { name: "Player 12", count: 0 },
    { name: "Player 13", count: 0 },
    { name: "Player 14", count: 0 },
    { name: "Player 15", count: 0 },
    { name: "Player 16", count: 0 },
    { name: "Player 17", count: 0 },
    { name: "Player 18", count: 0 },
    { name: "Player 19", count: 0 },
    { name: "Player 20", count: 0 },
  ],
} && { court: null, player: [], matchMode: 2 };
const COURT_MAX_SIZE = [4, 5];
function App() {
  const [data, setData] = useState(null);
  // const [data, setData] = useState(DEFAULT_DATA);
  useEffect(() => {
    if (data === null) {
      try {
        setData({ ...DEFAULT_DATA, ...JSON.parse(window.localStorage.getItem(STORAGE_KEY)) });
      } catch(e) {
        console.error(e);
        window.localStorage.removeItem(STORAGE_KEY);
        setData(DEFAULT_DATA);
      }
    } else {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    }
  }, [data]);
  function modify(f=()=>{}) {
    setData(d => {
      const res = JSON.parse(JSON.stringify(d));
      return f(res) || res;
    });
  }

  const { court, player, matchMode } = { ...DEFAULT_DATA, ...data };
  const [N_court] = useMemo(() => {
    if (!court) return [];
    let ans = 0;
    for (let i of court)
      for (let j of i)
        if (j)
          ans = Math.max(ans, j);
    return [ans];
  }, [court]);
  const [matches, setMatches] = useState([]);
  useEffect(() => {
    if (N_court) {
      setMatches(new Array(N_court).fill(null));
    }
  }, [N_court]);
  const playing = useMemo(() => new Set(matches.map(e => e === null ? [] : [...e[0], ...e[1]]).flat()), [matches]);

  const [dialog, setDialog] = useState(null);
  function closeDialog() { setDialog(null); }
  const [auto, setAuto] = useState(false);

  const pick = useCallback(() => {
    const available = player.filter(e => !playing.has(e.name));
    if (available.length < 2 * matchMode) return false;
    const count_threshold = available.sort((a, b) => a.count - b.count)[2 * matchMode - 1].count;
    const picked = available.filter(e => e.count < count_threshold);
    picked.push(...shuffle(available.filter(e => e.count === count_threshold)).slice(0, 2 * matchMode - picked.length));
    return [
      picked.map(e => e.name).slice(0, matchMode),
      picked.map(e => e.name).slice(matchMode, 2 * matchMode)
    ];
  }, [matchMode, player, playing]);

  useEffect(() => {
    if (!auto) return;
    for (let i = 0; i < matches.length; i++) if (matches[i] === null) {
      const picked = pick();
      if (picked === false) {
        setAuto(false);
      } else {
        dispatch(i, picked);
      }
      return;
    }
    setAuto(false);
  }, [auto, matches, data, pick]);

  const [announce, setAnnounce] = useState([]);
  useEffect(() => {
    if (announce.length && announce[0]) {
      setAnnounce(a => a.map((e, i) => i === 0 ? false : e));
      (async() => {
        if (typeof(announce[0]) === "number") {
          await new Promise((ok, no) => setTimeout(ok, announce[0] * 1000));
          setAnnounce(a => a.slice(1));
        } else {
          await speak(announce[0]);
          setAnnounce(a => a.slice(1));
        }
      })();
    }
  }, [announce, setAnnounce]);

  function dispatch(idx, mtch) {
    setMatches(m => m.map((e, i) => i === idx ? mtch : e));
    setAnnounce(a => [].concat(a, [`${idx+1}號場`], mtch.flat().map(
      e => e.replace(/-/g, ' ')
    ), [1.5]));
  }

  if (data === null) {
    return "Loading...";
  }
  return (
    <>
      <Main>
        <PlayerList>
          <PlayerItem>
            <Button variant="contained" onClick={() => {
              setDialog("add_player");
            }}>新增</Button>
            <Button variant="contained" onClick={() => {
              if (window.confirm("重設出場次數？"))
                modify(d => {
                  d.player = d.player.map(e => ({ ...e, count: 0 }))
                });
            }}>重設次數</Button>
          </PlayerItem>
          {Array.from(player).sort((a, b) => a.count - b.count).map((e, i) =>
            <PlayerItem key={i} style={{ justifyContent: 'space-between' }}>
              <Chip onDelete={() => {
                if (window.confirm(`確認刪除 ${e.name}？`))
                  modify(d => {
                    d.player.splice(i, 1);
                  });
              }} label={e.name} color={playing.has(e.name) ? "primary" : undefined} />
              <span>{e.count} 次</span>
            </PlayerItem>)}
          <PlayerItem>
            <Button variant="contained" color="error" onClick={() => {
              if (window.confirm(`確認清除名單？`))
                modify(d => {
                  d.player = [];
                });
            }}>清除</Button>
          </PlayerItem>
        </PlayerList>
        <CourtContainer>
          <CourtButtons>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={matchMode}
              exclusive
              onChange={(eve, val) => val !== null && modify(d => { d.matchMode = parseInt(val); })}
            >
              <ToggleButton value={1} sx={{ '&.Mui-selected, &.Mui-selected:hover': { bgcolor: 'primary.light' } }}>
                單打
              </ToggleButton>
              <ToggleButton value={2} sx={{ '&.Mui-selected, &.Mui-selected:hover': { bgcolor: 'primary.light' } }}>
                雙打
              </ToggleButton>
            </ToggleButtonGroup>
            <Button variant="contained" disabled={!court} onClick={() => {
              if (window.confirm("確定重設場地編排？"))
                modify(d => {
                  delete d.court;
                });
            }}>重設場地編排</Button>
            <Button
              variant="contained"
              onClick={() => setAuto(true)}
              disabled={!court}
            >自動安排</Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setAnnounce([])}
              disabled={!announce.length}
            >中斷廣播</Button>
          </CourtButtons>
          {court !== null ? court.map((row, i) =>
            <CourtRow key={i}>
              {row.map((idx, j) =>
                <CourtCol key={j}>
                  {Boolean(idx) && (matches[idx-1] ?
                    <Court
                      sx={{ cursor: 'pointer' }}
                      group1={matches[idx-1][0]}
                      group2={matches[idx-1][1]}
                      index={idx}
                      onClick={() => {
                        if (window.confirm(`確認完成場 ${idx}？\n${matches[idx-1][0].join('、')} vs ${matches[idx-1][1].join('、')}`)) {
                          setMatches(m => m.map((e, i) => i === idx-1 ? null : e));
                          modify(d => {
                            matches[idx-1].flat().forEach(e => {
                              d.player.filter(p => p.name === e).forEach(p => p.count += 1);
                            });
                          });
                        }
                      }}
                    /> :
                    <Court
                      sx={{ cursor: 'pointer' }}
                      empty
                      index={idx}
                      onClick={() => setDialog(["dispatch", idx-1])}
                    />
                  )}
                </CourtCol>)}
            </CourtRow>) :
            <CourtSelection
              row={COURT_MAX_SIZE[0]}
              col={COURT_MAX_SIZE[1]}
              onSelect={(list, table) => {
                modify(d => {
                  const offsetL = [list.map(e => e[0]), list.map(e => e[1])].map(e => Math.min(...e));
                  const offsetR = [list.map(e => e[0]), list.map(e => e[1])].map(e => Math.max(...e)+1);
                  d.court = table.slice(offsetL[0], offsetR[0]).map(e => e.slice(offsetL[1], offsetR[1]));
                });
                setMatches([]);
              }}
            />}
        </CourtContainer>
      </Main>
      <AddPlayer
        open={dialog === "add_player"}
        onClose={closeDialog}
        add={(name, count) => modify(d => {
          d.player.push({ name, count });
        })}
        existing={player.map(e => e.name)}
        avgCount={player.length === 0 ? null :
          parseInt(player.map(e => e.count).reduce((a, b) => a + b, 0) / player.length)}
      />
      <Dispatcher
        open={dialog?.[0] === "dispatch"}
        onClose={closeDialog}
        matchMode={matchMode}
        pool={player.map(e => e.name).filter(e => !playing.has(e))}
        pick={pick}
        dispatch={(playerList) => dispatch(dialog[1], playerList)}
      />
    </>
  );
}

const CourtSelectionCell = styled.td`
  width: 40px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
`;
function CourtSelection({ row=4, col=4, onSelect=()=>{} }) {
  const [selections, setSelections] = useState([]);
  const courts = useMemo(() => {
    const res = new Array(row).fill(0).map(e => new Array(col).fill(null));
    selections.forEach(([r, c], idx) => res[r][c] = idx+1);
    return res;
  }, [row, col, selections]);

  function toggle(row, col) {
    setSelections(sel => {
      const res = sel.filter(e => e[0] !== row || e[1] !== col);
      return res.length === sel.length ? sel.concat([[row, col]]) : res;
    });
  }

  return (
    <>
      <table>
        <tbody>
          {courts.map((row, i) =>
            <tr key={i}>
              {row.map((col, j) =>
                <CourtSelectionCell
                  onClick={() => toggle(i, j)}
                  key={j}
                  style={col === null ? {} : { backgroundColor: 'lightblue' }}
                >
                  {col === null || col}
                </CourtSelectionCell>)}
            </tr>)}
        </tbody>
      </table>
      <Button variant="contained" onClick={() => {
        if (selections.length === 0) {
          return window.alert("場地數不可為 0");
        }
        onSelect(selections, courts)
      }}>確認</Button>
    </>
  );
}

const NameList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
`;

function AddPlayer({ open, onClose, existing=[], avgCount=null, add=()=>{} }) {
  const [name, setName] = useState("");
  const nameList = useMemo(() => name.trim().split("\n").map(e => e.trim()).filter(e => e.length), [name]);
  const duplicated = useMemo(() => nameList.map(
    (e, i) => [].concat(existing, nameList.slice(0, i)).includes(e)
  ), [nameList, existing]);
  const [count, setCount] = useState(0);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>新增球員</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <TextField
            multiline
            autoFocus
            required
            margin="dense"
            label="名稱"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <NameList>
            {nameList.map((e, i) =>
              <Chip
                key={i}
                label={e}
                color={duplicated[i] ? "error" : undefined}
              />)}
          </NameList>
          <div>
            <FormLabel>預設出場次數</FormLabel>
            <RadioGroup row value={count} onChange={e => setCount(e.target.value)}>
              <FormControlLabel value="0" control={<Radio />} label="0次" />
              {avgCount !== null && avgCount !== 0 &&
                <FormControlLabel value={avgCount} control={<Radio />} label={`${avgCount}次（當前平均）`} />}
            </RadioGroup>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={() => {
          if (duplicated.some(e => e))
            return window.alert("名稱重複");
          nameList.forEach(n => add(n.trim(), parseInt(count)));
          setName("");
          onClose();
        }}>新增</Button>
      </DialogActions>
    </Dialog>
  );
}

const MatchList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 5px;
`;

function Dispatcher({ open, onClose, pool, pick=false, matchMode=2, dispatch=()=>{} }) {
  const empty = useMemo(() => new Array(matchMode * 2).fill(null), [matchMode]);
  const [nameList, setNameList] = useState(empty);
  useEffect(() => {
    setNameList(empty);
  }, [matchMode, empty]);
  const matchList = useMemo(() => [
    nameList.slice(0, matchMode), nameList.slice(matchMode)
  ], [nameList, matchMode]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>分配球員</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <MatchList>
            {matchList[0].map((e, i) => <Chip key={i} label={e || "?"} />)}
            <span>vs</span>
            {matchList[1].map((e, i) => <Chip key={i} label={e || "?"} />)}
          </MatchList>
          {pick &&
            <Divider>
              <Button variant="outlined" onClick={() => {
                const picked = pick();
                if (picked !== false)
                  setNameList(picked.flat());
              }}>自動分配</Button>
            </Divider>}
          <NameList>
            {pool.map((e, i) =>
              <Chip
                key={i}
                label={e}
                variant={nameList.includes(e) ? "contained" : "outlined"}
                color={nameList.includes(e) ? "primary" : undefined}
                onClick={() => {setNameList(l => {
                  l = Array.from(l);
                  for (let i in l) if (l[i] === e) {
                    l[i] = null;
                    return l;
                  }
                  for (let i in l) if (l[i] === null) {
                    l[i] = e;
                    break;
                  }
                  return l;
                })}}
              />)}
          </NameList>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={() => {
          dispatch(matchList);
          setNameList(empty);
          onClose();
        }} disabled={nameList.some(e => e === null)}>確認</Button>
      </DialogActions>
    </Dialog>
  );
}

export default App;
